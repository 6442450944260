.image-previewer-dialog {
  font-size: 0;
  .tea-dialog__inner {
    background-color: transparent;
    width: 100%;
    height: 100%;
    box-shadow: none;
    font-size: 12px;
    padding: 0;
    .image-previewer-dialog-header {
      top: 0;
      height: 60px;
      padding: 0 25px;
      background-color: #000;
      display: flex;
      align-items: center;
      .image-previewer-dialog-header-title {
        color: #fff;
        width: 100%;
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 700;
        display: inline-block;
        line-height: 22px;
      }
      .tea-btn--icon {
        padding: 12px;
        border-radius: 100%;
        top: 10px;
        right: 14px;
      }
    }
    .image-previewer-dialog-body {
      width: 90%;
      height: calc(100% - 130px);
      margin: 55px auto 0;
      text-align: center;
      overflow: auto;
      font-size: 0;
      img {
        max-width: 100%;
        vertical-align: middle;
      }
      &::before {
        content: "";
        display: inline-block;
        width: 0;
        height: 100%;
        vertical-align: middle;
      }
      &::after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }
  
}