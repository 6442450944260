$nav-height: 50px;
$menu-width: 200px;

.spaui-topnav-nav>li {
  border: none!important;
}
.layout {
  height: 100%;
  width: 100%;
  min-width: 1280px;
  padding-top: $nav-height;
  padding-left: $menu-width;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  main {
    width: 100%;
    height: 100%;
    background-color: #f3f3f3;
    overflow-y: auto;
    position: relative;
    box-sizing: border-box;
    padding: 0 20px 20px;
    position: relative;
    display: flex;
    flex-direction: column;

    & > * {
      box-sizing: content-box;
    }
  }
}

.layout-bread + div {
  flex-grow: 1;
}
.layout-bread {
  padding-left: 20px;
  flex-shrink: 0;
  height: 53px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 10px;
  line-height: 53px;
  background-image: url("https://fxd-1256737511.file.myqcloud.com/fang/temp/9b379712cdb7f1fb8c2d2324146bb720");
  background-repeat: repeat-x;

  .tea-breadcrumb__item {
    padding: 0;
    margin: 0;
    font-weight: 700;
    &::before {
      color: #fff !important;
    }

    .tea-breadcrumb__item-title {
      color: #fff !important;
    }
  }
}

.layout-nav {
  position: absolute;
  width: 100%;
  height: $nav-height;
  left: 0;
  top: 0;
  background-color: #5a66ff;

  .spaui-topnav {
    background-color: #5a66ff;
  }
}

.layout-menu {
  width: $menu-width;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: $nav-height + 32px;
  box-sizing: border-box;
  background-color: #fff;

  .layout-menu__content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    // 隐藏滚动条
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .layout-menu__title {
    font-size: 16px;
    color: #222;
    font-weight: 600;
    line-height: 2em;
    padding-left: 1em;
    position: absolute;
    top: $nav-height;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .spaui-menu,
  .spaui-submenu {
    width: unset;
    max-width: unset;
  }

  .tea-menu__text {
    padding-left: 25px;
    position: relative;
  }

  .tea-menu__submenu .tea-menu__list {
    .tea-menu__item:before {
      display: none;
    }

    // 子菜单前面加个前缀“·”
    // .tea-menu__text:before {
    //   content: "";
    //   background-color: #7c878e;
    //   width: 3px;
    //   height: 3px;
    //   border-radius: 10px;
    //   margin-right: 14px;
    //   display: inline-block;
    //   vertical-align: text-top;
    //   margin-top: 6px;
    //   margin-left: 6px;
    // }
  }

  .svg-icon {
    fill: #888;
    vertical-align: top;
    left: 0;
    margin-top: 4px;
    // vertical-align: text-bottom;
    // position: absolute;
    // margin-top: 1px;
  }

  .tea-menu__item > .tea-menu__text,
  .tea-menu__submenu.is-expanded > .tea-menu__item > .tea-menu__text,
  .tea-menu__submenu .tea-menu__list .tea-menu__text {
    color: #888;
  }

  .tea-menu__body > .tea-menu__list > li > a {
    & > .tea-menu__text {
      font-weight: 700;
      font-size: 14px;
      user-select: none;
    }

    .tea-icon.tea-icon-arrowdown {
      background-size: 100%;
      width: 24px;
      height: 24px;
      top: unset;
    }
  }

  .spaui-menuitem.spaui-menu-selected:not(.has-submenu) {
    color: var(--spaui-link-color);
  }

  .spaui-menu-fontawesome {
    line-height: 1em;
  }

  .spaui-menu-selected .spaui-menu-fontawesome svg {
    fill: var(--spaui-link-color);
  }

  li.spaui-menuitem a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.spaui-topnav-left {
  .logo {
    background-color: #5a66ff;
  }
}

#navtitle-container-group {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #fff;

  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: "";
    width: 1px;
    height: 24px;
    background-color: #fff;
    opacity: 0.5;
  }

  .logo {
    margin-left: 16px;
    margin-right: 8px;
    width: 28px;
    height: 28px;
    border-radius: 50%;

    > img {
      width: 100%;
      height: 100%;
      transform: scale(.8);
    }
  }

  .tip {
    //display:block;
    background:#f00;
    border-radius:50%;
    width:8px;
    height:8px;
    top:0px;
    right:0px;
    margin-left: 16px;
    //position:absolute;
  }

  .annicon {
    width:16px;
    height:16px;
    background-color:#FFFFFF;
    border-radius:8px;
    border-width: 1px;
    margin-left: 16px;
  }

  .anniconspan {
    height:16px;
    line-height:16px;
    display:block;
    color:#5a66ff;
    text-align:center;
    font-size:16px
  }

  .announcenew {
    margin-left: 6px;
    cursor: pointer;
  }

  .announcenewmore {
    margin-left: 16px;
    color: darkred;
    cursor: pointer;
  }

  .check {
    .selection-container {
      width: 100%;
    }

    .selection-single {
      background-color: #5a66ff;
      border: 1px solid transparent;
      color: #fff;
    }

    svg {
      color: #fff;
    }

    .selection-results {
      color: #000;
    }
  }
}

.tea-datepicker__input:after {
  display: none;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
