.c-no-ad-account-alert {
  p {
    font-size: 18px;
    font-weight: bold;
  }

  label {
    user-select: none;
  }

  .modal-footer {
    text-align: center;
  }
}
